import {Component, OnDestroy} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {AuthService} from "../../service/auth.service";
import {ToastrService} from "ngx-toastr";
import {AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators} from "@angular/forms";
import {PasswordRecoveryRequest} from "../../dto/password-recovery-request";
import {ChangePasswordRequest} from "../../dto/change-password-request";
import {ReplaySubject, takeUntil} from "rxjs";
import {ErrorDTO} from "../../dto/error-dto";
import {validPasswordPattern} from "../external-user-register/external-user-register.component";

@Component({
  selector: 'app-password-recovery',
  templateUrl: './password-recovery.component.html',
  styleUrls: ['./password-recovery.component.css']
})
export class PasswordRecoveryComponent implements OnDestroy {

  email: string;
  token: string;
  username: string;
  inProgress = false;
  form: FormGroup;
  formEmail: FormGroup;
  passwordRecoveryRequest: PasswordRecoveryRequest;
  inputCP = 'password';
  inputP = 'password';
  changePasswordRequest: ChangePasswordRequest;
  destroy: ReplaySubject<void> = new ReplaySubject<void>(1);

  constructor(private router: Router,
              private authService: AuthService,
              private toastr: ToastrService,
              private activatedRoute: ActivatedRoute) {

    const state = this.router.getCurrentNavigation().extras.state as {email: string};
    if (state)
      this.email = state.email;

    this.activatedRoute.queryParams.pipe(takeUntil(this.destroy)).subscribe(params => {
      this.token = params['token'];
    });

    if (this.token) {
      this.username = this.authService.getUsernameFromToken(this.token);
    }

    this.form = new FormGroup({
      email: new FormControl(this.email ? this.email : this.username, [Validators.email, Validators.required]),
      password: new FormControl('', [Validators.required, Validators.pattern(validPasswordPattern)]),
      confirmPassword: new FormControl('', Validators.required),
    }, { validators: this.checkPasswords });

    this.formEmail = new FormGroup({
      email: new FormControl(this.email ? this.email : this.username, [Validators.email, Validators.required]),
    });
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  recovery() {
    if (this.formEmail.invalid) {
      this.formEmail.markAllAsTouched();
    } else {
      this.inProgress = true;
      this.passwordRecoveryRequest = {...this.passwordRecoveryRequest, ...this.formEmail.value};
      this.authService.recoveryPassword(this.passwordRecoveryRequest).pipe(takeUntil(this.destroy)).subscribe({
        next: (response) => {
          this.inProgress = false;
          this.toastr.success(response.message, '');
          this.router.navigate(['/']);
        },
        error: (error) => {
          this.inProgress = false;
          let errors = <ErrorDTO[]>error.error.errors;
          errors.forEach(e=> this.toastr.error(e.errorMessage, ''));
        }
      });
    }
  }

  changePassword() {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      if (this.form.errors && this.form.errors['notSame']) {
        this.toastr.error('Password & Confirm Password must be the same', '');
      }
    } else {
      this.inProgress = true;
      this.changePasswordRequest = {...this.changePasswordRequest,...this.form.value};
      this.changePasswordRequest.token = this.token;
      this.authService.changePassword(this.changePasswordRequest).pipe(takeUntil(this.destroy)).subscribe({
        next: (response)=> {
          this.toastr.success(response.message, '');
          this.router.navigate(['/']);
          this.inProgress = false;
        },
        error: (error) => {
          let errors = <ErrorDTO[]>error.error.errors;
          errors.forEach(e=> this.toastr.error(e.errorMessage, ''));
          this.inProgress = false;
        }
      });
    }
  }

  checkPasswords: ValidatorFn = (group: AbstractControl):  ValidationErrors | null => {
    const pass = group.get('password').value;
    const confirmPass = group.get('confirmPassword').value;
    return pass === confirmPass ? null : { notSame: true };
  }

  changeInputTypeCP() {
    this.inputCP = this.inputCP === 'password' ? 'text': 'password';
  }

  changeInputTypeP() {
    this.inputP = this.inputP === 'password' ? 'text': 'password';
  }
}
