import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {AuthService} from "../service/auth.service";
import {ReplaySubject, takeUntil} from "rxjs";
import {UserRole} from "../dto/user-role";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit, OnDestroy {

  showButton = false;
  destroy: ReplaySubject<void> = new ReplaySubject<void>(1);
  isAuthenticated = false;
  agencyMode = false;

  constructor(private authService: AuthService) {
  }

  ngOnInit(): void {
    this.authService.isAuth.pipe(takeUntil(this.destroy)).subscribe(result => {
      this.isAuthenticated = result;
      if (this.isAuthenticated) {
        this.agencyMode = this.authService.getUserRole() === UserRole.ROLE_AGENCY;
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  @HostListener('window:scroll', ['$event'])
  scroll() {
    this.showButton = window.scrollY > 100;
  }

  onTopPage() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

}
