import {Component, OnDestroy, OnInit} from '@angular/core';
import {User} from "../dto/user";
import {AuthService} from "../service/auth.service";
import {Router} from "@angular/router";
import {ReplaySubject, takeUntil} from "rxjs";
import {UserRole} from "../dto/user-role";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})

export class HeaderComponent implements OnInit, OnDestroy {

  user: User;
  isAuthenticated = false;
  agencyMode = false;
  username: string;
  role: string;
  destroy: ReplaySubject<void> = new ReplaySubject<void>(1);

  constructor(private authService: AuthService,
              private router: Router) {
  }

  ngOnInit(): void {
    this.authService.isAuth.pipe(takeUntil(this.destroy)).subscribe(result => {
      this.isAuthenticated = result;
      if (this.isAuthenticated) {
        this.role = this.authService.getUserRole();
        this.username = this.authService.getUserName();
        this.agencyMode = this.role === UserRole.ROLE_AGENCY;
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  redirectTo() {
    this.router.navigate([this.authService.isAuthenticated() ? '/referrer': '/']);
  }

  logout() {
    this.authService.logout();
    this.router.navigate(['']);
  }

  changePassword() {
    this.router.navigate([this.authService.isAuthenticated() ? '/referrer/change-password': '/']);
  }
}
