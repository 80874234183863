<div class="content">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h2>Welcome to Referral Program</h2>
      </div>
    </div>
    <div class="row margin-bottom-15">
      <div class="col-md-6 col-sm-12">
        If you have used the platform before, but don’t have the password yet or in case you forgot your password, please use <strong>forgot password</strong> option to create a new password. If you are not registered in the Referral Platform yet <strong>create an account</strong>.<br>
        Please note that the Referral platform is available for individual external referrer providers only in a <strong>limited number of countries</strong>. Please check
        <a href="https://career.luxoft.com/referral-program/" class="color-purple">the rules and eligibility criteria</a> first.
      </div>
    </div>
    <form *ngIf="!token" class="row form-content" [formGroup]="form" (ngSubmit)="submit()">
      <div class="col-md-6">
        <div class="row">
          <div class="col-md-6 name-margin">
            <label class="control-label" for="first-name">First name <span class="color-red">*</span></label>
            <input placeholder="First name" type="text" id="first-name" class="form-control" formControlName="firstName">
            <div *ngIf="form.get('firstName').invalid && form.get('firstName').touched" class="validation">
              <small *ngIf="form.get('firstName').errors['required'] || form.get('firstName').errors['pattern']">First name cant be empty</small>
              <small *ngIf="form.get('firstName').errors['forbiddenStrings']">Invalid field value</small>
            </div>
          </div>
          <div class="col-md-6">
            <label class="control-label" for="last-name">Last name <span class="color-red">*</span></label>
            <input placeholder="Last name" type="text" id="last-name" class="form-control" formControlName="lastName">
            <div *ngIf="form.get('lastName').invalid && form.get('lastName').touched" class="validation">
              <small *ngIf="form.get('lastName').errors['required'] || form.get('lastName').errors['pattern']">Last name cant be empty</small>
              <small *ngIf="form.get('lastName').errors['forbiddenStrings']">Invalid field value</small>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="email" class="control-label">Email <span class="color-red">*</span></label>
          <input (change)="checkWrongAddress()" placeholder="myEmail@mail.com" type="text" id="email" formControlName="email" class="form-control">
          <div *ngIf="form.get('email').invalid && form.get('email').touched" class="validation">
            <small *ngIf="form.get('email').errors['required']">Email cant be empty</small>
            <small *ngIf="form.get('email').errors['email']">Write Correct Email</small>
          </div>
          <div *ngIf="wrongEmail" class="validation">
            <small>Warning: Users with .pl mailboxes may experience delays and technical issues when logging into the Referral Platform.<br>
              Therefore, we kindly advise that you use mailboxes from other email providers when accessing the platform.</small>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 name-margin" style="position: relative">
            <label for="email" class="control-label">Password <span class="color-red">*</span></label>
            <input autocomplete="new-password" type="{{inputP}}" id="password" formControlName="password" class="form-control initial">
            <em><mat-icon class="icon" (click)="changeInputTypeP()">remove_red_eye</mat-icon></em>
            <div *ngIf="form.get('password').invalid && form.get('password').touched" class="validation">
              <small *ngIf="form.get('password').errors['required']">Password cant be empty</small>
              <small *ngIf="form.get('password').errors['pattern']">The password is incorrect, please enter the password
                of minimum 8 characters that includes upper and lower case letters, digits and special symbols</small>
            </div>
          </div>
          <div class="col-md-6" style="position: relative">
            <label for="email" class="control-label">Confirm Password <span class="color-red">*</span></label>
            <input autocomplete="new-password" type="{{inputCP}}" id="confirm-password" formControlName="confirmPassword" class="form-control initial">
            <em><mat-icon class="icon" (click)="changeInputTypeCP()">remove_red_eye</mat-icon></em>
            <div *ngIf="form.get('confirmPassword').invalid && form.get('confirmPassword').touched" class="validation">
              <small *ngIf="form.get('confirmPassword').errors['required']">Confirm password cant be empty</small>
            </div>
          </div>
        </div>
        <div class="form-group form__item">
          <label for="country" class="control-label">Country</label>
          <ng-select [items]="countryList"
                     [clearable]="false"
                     bindLabel="country"
                     placeholder="Select country"
                     labelForId="country"
                     formControlName="country">
          </ng-select>
          <div *ngIf="form.get('country').invalid && form.get('country').touched" class="validation">
            <small *ngIf="form.get('country').errors['required']">Country name cant be empty</small>
          </div>
        </div>
        <div class="col-md-12">
          <p>By clicking *Create account* button I give my consent for Luxoft
            Holding inc. (Data Controller) and its affiliates (Luxoft Group) to process my personal
            data for the purposes of potential employment, internship or relocation to Luxoft Group
            entity or its client, as well as to provide recommendations of candidates for work and
            to award me a bonus for such recommendation, if applicable.</p>
        </div>
        <div class="col-12">
          <button [disabled]="inProgress" type="submit" class="btn custom-btn"><span
            *ngIf="inProgress" class="spinner-border sr-only"></span>&nbsp;Create account</button>
        </div>
        <div class="col-12 log-in">
          <p>Already have an account? <a class="anchor" [routerLink]="['']">Log in</a></p>
        </div>
      </div>
    </form>
    <div *ngIf="token" class="row">
      <div class="col-12">
        <h2><span class="spinner-border sr-only"></span> Your request is Processing... </h2>
      </div>
    </div>
  </div>
</div>
