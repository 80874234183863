import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {Country} from "../dto/country";

@Injectable({
  providedIn: 'root'
})
export class CountryService {

  private url = environment.apiUrl;

  constructor(private http: HttpClient) { }

  getCountryList() {
    return this.http.get<Country[]>(this.url + "countries");
  }
}
