import {SourceType} from "./source-type";
import {UserRole} from "./user-role";

export class User {
  uid: string;
  summary: string;
  phone: string;
  active: boolean;
  role: UserRole;
  createdAt: string;
  email: string;
  countryName: string;
  countryCode: string;
  platformType: SourceType;
  password: string;
  confirmPassword: string;
  recommenderKey: string;
}
