import {EventEmitter, Injectable} from '@angular/core';
import {environment} from "../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {User} from "../dto/user";
import {of, tap} from "rxjs";
import {ChangePasswordRequest} from "../dto/change-password-request";
import {ResponseDto} from "../dto/response-dto";

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private url = environment.apiUrl;
  private user: User;

  constructor(private http: HttpClient) { }

  getUser() {
    return this.http.get<User>(this.url + "users")
      .pipe(
        tap(
          (user) => {
            this.setUser(user);
          }
        )
      );
  }

  setUser(user: User) {
    this.user = user;
  }

  getExistUser() {
    return this.user ? of(this.user) : this.getUser();
  }

  updateUserPassword(request: ChangePasswordRequest) {
    return this.http.post<ResponseDto>(this.url + 'users/update-password', request);
  }
}
