<!-- Google Tag Manager (noscript) -->
<noscript><iframe title="google tag manager" src="https://www.googletagmanager.com/ns.html?id=GTM-PMG8KD7"
                  height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
<!-- End Google Tag Manager (noscript) -->

<div class="main">
  <app-header></app-header>
  <router-outlet></router-outlet>
  <app-footer></app-footer>
</div>
