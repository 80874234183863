<div class="content">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h2 *ngIf="!username">Forgot password?</h2>
        <h2 *ngIf="username">Create new password</h2>
      </div>
    </div>
    <form *ngIf="!token" class="row form-content" [formGroup]="formEmail" (ngSubmit)="recovery()">
      <div class="col-md-4" >
        Enter the email address you used when you joined Referral Platform and we'll send you the link to reset your password
      </div>
      <div class="row form-group-email">
        <div class="col-md-4">
          <label for="email" class="control-label">Email</label>
          <input placeholder="user@mail.com" type="text" id="email" formControlName="email" class="form-control">
          <div *ngIf="formEmail.get('email').invalid && formEmail.get('email').touched" class="validation">
            <small *ngIf="formEmail.get('email').errors['required']">Email cannot be empty</small>
            <small *ngIf="formEmail.get('email').errors['email']">Write Correct Email</small>
          </div>
        </div>
      </div>
      <div class="row form-group">
          <div class="col-md-4">
            <button [disabled]="inProgress" type="submit" class="btn custom-btn width-100"><span
              *ngIf="inProgress" class="spinner-border sr-only"></span>&nbsp;Send Email
            </button>
          </div>
        </div>
       <div class="row">
         <div class="col-md-4 log-in">
           <p>Remember password? <a class="anchor" [routerLink]="['']">Log in</a></p>
         </div>
       </div>
    </form>

    <form *ngIf="token" class="row form-content" [formGroup]="form" (ngSubmit)="changePassword()">
      <div class="col-md-4-5">
        <div>
          Enter your new password, confirm it and click the button 'Login with new password'
        </div>
      </div>
      <div class="row form-group m-b-0">
        <div class="col-md-4-5" style="position: relative">
          <label class="control-label" for="password">New Password <span class="color-red">*</span></label>
          <input autocomplete="new-password" type="{{inputP}}" id="password" class="form-control initial" formControlName="password">
          <em><mat-icon class="icon" (click)="changeInputTypeP()">remove_red_eye</mat-icon></em>
          <div *ngIf="form.get('password').invalid && form.get('password').touched" class="validation">
            <small *ngIf="form.get('password').errors['required']">Password cant be empty</small>
            <small *ngIf="form.get('password').errors['pattern']">The password is incorrect, please enter the password
              of minimum 8 characters that includes upper and lower case letters, digits and special symbols</small>
          </div>
        </div>
      </div>
      <div class="row form-group m-b-0">
        <div class="col-md-4-5" style="position: relative">
          <label class="control-label" for="confirm-password">Repeat new password <span
            class="color-red">*</span></label>
          <input autocomplete="new-password" type="{{inputCP}}" id="confirm-password" class="form-control initial" formControlName="confirmPassword">
          <em><mat-icon class="icon" (click)="changeInputTypeCP()">remove_red_eye</mat-icon></em>
          <div *ngIf="form.get('confirmPassword').invalid && form.get('confirmPassword').touched" class="validation">
            <small *ngIf="form.get('confirmPassword').errors['required']">Repeat new password cant be empty</small>
          </div>
        </div>
      </div>
      <div class="row form-group m-b-100">
        <div class="col-md-4-5">
          <button [disabled]="inProgress" type="submit" class="btn custom-btn"><span
            *ngIf="inProgress" class="spinner-border sr-only"></span>&nbsp;Log in with new password
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
