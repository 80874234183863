export class Vacancy {
  id: number;
  vrKey: string;
  vrName: string;
  created: Date;
  specializationUid: string;
  specializationName: string;
  countryCode: string;
  countryName: string;
  skillsRequired: string;
  niceToHaveSkills: string;
  responsibilities: string;
  selected: boolean;
  showDetails: boolean;
  workingPlaceCity: string;
  projectDescription: string;
}
