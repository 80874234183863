import {EventEmitter, Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {TokenDTO} from "../dto/token-dto";
import {tap} from "rxjs";
import {environment} from "../../environments/environment";
import {UserService} from "./user.service";
import {RegisterRequest} from "../dto/register-request";
import {LoginRequest} from "../dto/login-request";
import {ResponseDto} from "../dto/response-dto";
import {PasswordRecoveryRequest} from "../dto/password-recovery-request";
import {ChangePasswordRequest} from "../dto/change-password-request";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private url = environment.apiUrl;
  isAuth:EventEmitter<boolean> = new EventEmitter();
  private _isAuth: boolean;

  constructor(private http: HttpClient,
              private userService: UserService) { }

  loginSSO(token: TokenDTO) {
    return this.http.post<TokenDTO>(this.url + 'auth/login-sso', token)
      .pipe(
        tap(
          ({token}) => {
            this.setToken(token);
          }
        )
      );
  }
  registrationExternalUser(registrationRequest: RegisterRequest) {
    return this.http.post<ResponseDto>(this.url + 'auth/registrationExternalUser', registrationRequest);
  }

  loginExternalUser(loginRequest: LoginRequest) {
    return this.http.post<TokenDTO>(this.url + 'auth/loginExternalUser', loginRequest)
      .pipe(
        tap(
          ({token}) => {
            this.setToken(token);
          }
        )
      );
  }


  finishRegistration(token: TokenDTO) {
    return this.http.post<ResponseDto>(this.url + 'auth/finishRegistration', token);
  }


  recoveryPassword(passwordRecoveryRequest: PasswordRecoveryRequest) {
    return this.http.post<ResponseDto>(this.url + 'auth/passwordRecovery', passwordRecoveryRequest);
  }

  changePassword(changePasswordRequest: ChangePasswordRequest) {
    return this.http.post<ResponseDto>(this.url + 'auth/changePassword', changePasswordRequest);
  }

  logout() {
    localStorage.removeItem('token');
    this.userService.setUser(null);
    this.isAuth.emit(false);
    this._isAuth = false;
  }

  setToken(token: string) {
    localStorage.setItem('token', token);
  }

  getToken() {
    if (localStorage.getItem('token') != null) {
      return 'Bearer ' + localStorage.getItem('token');
    }
    return null;
  }

  isAuthenticated(): boolean {
    const result = !!this.getToken() && this.isTokenNotExpired(this.getToken());
    if (this._isAuth !== result){
      this.isAuth.emit(result);
      this._isAuth = result;
    }
    return result;
  }

  isTokenNotExpired(token: string): boolean {
    const expiry = (JSON.parse(atob(token.split('.')[1]))).exp;
    return expiry * 1000 > Date.now();
  }

  getUsernameFromToken(token: string): string {
    return (JSON.parse(atob(token.split('.')[1]))).username;
  }

  getUserRole(): string {
    return (JSON.parse(atob(this.getToken().split('.')[1]))).role;
  }

  getUserName(): string {
    return (JSON.parse(atob(this.getToken().split('.')[1]))).username;
  }

  isLoggedGoogle(): boolean {
    return !!localStorage.getItem('logged-google');
  }
}
