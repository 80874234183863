<div class="navbar">
  <div class="container">
    <div class="row big">
      <div class="col-2">
        <div class="nav__content">
            <span (click)="redirectTo()" class="image">
              <img class="col" src="/assets/images/logo.svg" alt="">
            </span>
        </div>
      </div>
      <div class="col-10 big-menu text-right" *ngIf="isAuthenticated">
        <ul class="links">
          <li routerLinkActive="active" *ngIf="!agencyMode">
            <a [routerLink]="['referrer/recommendations/new']" class="new-recommendation">New recommendation</a>
          </li>
          <li routerLinkActive="active">
            <a [routerLink]="['referrer/my-recommendations']" class="my-recommendations">My Recommendations</a>
          </li>
          <li routerLinkActive="active" *ngIf="agencyMode" class="list-border">
            <a [routerLink]="['referrer/vacancies']" class="vacancies">Vacancies</a>
          </li>
          <li routerLinkActive="active">
            <a [routerLink]="['referrer/information']" class="information">Information</a>
          </li>
        </ul>
        <span class="name">{{username}}
          <mat-icon [matMenuTriggerFor]="menu" class="icon pointer">keyboard_arrow_down</mat-icon></span>
        <mat-menu #menu="matMenu">
          <button *ngIf="role === 'ROLE_EXTERNAL_USER'" mat-menu-item (click)="changePassword()" class="change-password">Change password
          </button>
          <button mat-menu-item (click)="logout()" class="log-out">Log out</button>
        </mat-menu>
      </div>
    </div>
    <div class="small margin-bottom-20">
      <div class="row">
        <div class="nav__content">
            <span (click)="redirectTo()" class="image">
              <img class="col" src="/assets/images/logo.svg" alt="">
            </span>
        </div>
        <div>
          <div class="col-12 text-center" *ngIf="isAuthenticated">
            <ul class="links">
              <li routerLinkActive="active" *ngIf="!agencyMode">
                <a [routerLink]="['referrer/recommendations/new']">New recommendation</a>
              </li>
              <li routerLinkActive="active">
                <a [routerLink]="['referrer/my-recommendations']">My Recommendations</a>
              </li>
              <li routerLinkActive="active" *ngIf="agencyMode" class="list-border">
                <a [routerLink]="['referrer/vacancies']">Vacancies</a>
              </li>
              <li routerLinkActive="active">
                <a [routerLink]="['referrer/information']">Information</a>
              </li>
            </ul>
           <span class="login">
              <span class="name">{{username}}
                <mat-icon [matMenuTriggerFor]="menu" class="icon pointer">keyboard_arrow_down</mat-icon></span>
             <mat-menu #menu="matMenu">
               <button *ngIf="role === 'ROLE_EXTERNAL_USER'" mat-menu-item (click)="changePassword()">Change password
               </button>
               <button mat-menu-item (click)="logout()">Log out</button>
             </mat-menu>
           </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
