import { Injectable } from '@angular/core';
import {environment} from "../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Specialization} from "../dto/specialization";

@Injectable({
  providedIn: 'root'
})
export class SpecializationService {

  private url = environment.apiUrl;

  constructor(private http: HttpClient) { }

  getSpecializationList() {
    return this.http.get<Specialization[]>(this.url + 'specializations');
  }
}
