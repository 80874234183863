import {NgModule} from "@angular/core";
import {PreloadAllModules, RouterModule, Routes} from "@angular/router";
import {LoginComponent} from "./pages/login/login.component";
import {ExternalUserRegisterComponent} from "./pages/external-user-register/external-user-register.component";
import {PasswordRecoveryComponent} from "./pages/password-recovery/password-recovery.component";
import {LoginGuard} from "./guard/login.guard";

const routes: Routes = [
  {
    path: '', component: LoginComponent, children: [
      {path: '', redirectTo: 'login', pathMatch: 'full'},
      {path: 'login', component: LoginComponent, canActivate: [LoginGuard]},
    ]
  },
  {path: 'external-users/register', component: ExternalUserRegisterComponent, canActivate: [LoginGuard]},
  {path: 'password-recovery', component: PasswordRecoveryComponent, canActivate: [LoginGuard]},
  {
    path: 'referrer', loadChildren: ()=> import('./pages/referrer/referrer.module').then(mod => mod.ReferrerModule)
  },
  {path: '**', redirectTo: 'login'}
];


@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule{}
