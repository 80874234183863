import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {Recommendation} from "../dto/recommendation";

@Injectable({
  providedIn: 'root'
})
export class RecommendationService {

  private url = environment.apiUrl;

  constructor(private http: HttpClient) { }

  createRecommendation(recommendation: Recommendation) {
    return this.http.post<Recommendation>(this.url + 'recommendations', recommendation);
  }

  getRecommendationListByUserid(uid: string) {
    return this.http.get<Recommendation[]>(this.url + `recommendations/${uid}`);
  }

  getRecommendationByRecKey(recKey: string) {
    return this.http.get<Recommendation>(this.url + `recommendations/detailed/${recKey}`);
  }

  getRecommendationStatuses() {
    return this.http.get<string[]>(this.url + 'recommendations/statuses');
  }

  getRecommendationReport(uid: string) {
    return this.http.get(this.url + `recommendations/report/${uid}`, {responseType: 'blob'});
  }
}
