import {Component, OnDestroy, OnInit} from '@angular/core';
import {User} from "../../dto/user";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthService} from "../../service/auth.service";
import {TokenDTO} from "../../dto/token-dto";
import {ReplaySubject, takeUntil} from "rxjs";
import {MsalService} from "@azure/msal-angular";
import {AuthenticationResult} from "@azure/msal-browser";
import {ToastrService} from "ngx-toastr";
import {ErrorDTO} from "../../dto/error-dto";
import {LoginRequest} from "../../dto/login-request";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

  user: User;
  form: FormGroup;
  destination: string;
  inProgress = false;
  loginInProgress = false;
  inProgressOther = false;
  type = 'password';
  isVP: boolean;
  loginRequest: LoginRequest;
  destroy: ReplaySubject<void> = new ReplaySubject<void>(1);


  constructor(private authService: AuthService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private toastr: ToastrService,
              private msalService: MsalService
  ) {
    this.form = new FormGroup({
      email: new FormControl(null, [Validators.email, Validators.required]),
      password: new FormControl(null, [Validators.required])
    });
  }

  ngOnInit(): void {
    this.isVP = window.location.host.includes("vp.luxoft.com");
    this.activatedRoute.queryParams.pipe(takeUntil(this.destroy)).subscribe( params =>
      this.destination = params['destination']
    );
  }

  loginSSO() {
    this.inProgress = true;
    this.msalService.loginPopup().pipe(takeUntil(this.destroy)).subscribe({
     next: (response: AuthenticationResult) =>  {
       this.loginInProgress = true;
       this.authService.loginSSO(new TokenDTO(response.idToken)).pipe(takeUntil(this.destroy)).subscribe( {
         next: () => {
           if (this.destination) {
             const url = this.destination.split('?')[0];
             this.router.navigate([url], {queryParamsHandling: "merge"});
           } else {
             this.router.navigate(['/referrer']);
           }
           this.finalizeProgress();
         },
         error: (error) => {
           let errors = <ErrorDTO[]>error.error.errors;
           errors.forEach(e=> this.toastr.error(e.errorMessage, ''));
           this.finalizeProgress();
         }
       });
     },
      error: () => {
        this.finalizeProgress();
      }
    });
  }

  finalizeProgress() {
    this.loginInProgress = false;
    this.inProgress = false;
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  login() {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
    } else {
      this.inProgressOther = true;
      this.loginRequest = {...this.loginRequest, ...this.form.value};
      this.authService.loginExternalUser(this.loginRequest).pipe(takeUntil(this.destroy))
        .subscribe({
          next: () => {
            if (this.destination) {
              const url = this.destination.split('?')[0];
              this.router.navigate([url], {queryParamsHandling: "merge"});
            } else {
              this.router.navigate(['/referrer'], {state: {login: true}});
            }
            this.inProgressOther = false;
          },
          error: (error) => {
            let errors = <ErrorDTO[]>error.error.errors;
            errors.forEach(e => this.toastr.error(e.errorMessage, ''));
            this.inProgressOther = false;
          }
        });
    }
  }

  changeInputTypeP() {
    this.type = this.type === 'password' ? 'text': 'password';
  }
}
