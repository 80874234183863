<div class="content">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h2>Welcome to the <strong>Luxoft Referral Platform</strong>!</h2>
      </div>
      <div class="row mb-30">
        <div class="col-10">
          <p>The platform is available for Luxoft employees and private entrepreneurs who provide services to DXC Luxoft, external reference providers and recruitment agencies.</p>
        </div>
      </div>
    </div>
    <div class="row main-block">
      <div class="col-md-4 sso-block col-sm-12">
        <div class="col-12 upper text-center">
          For Luxoft employees only
        </div>
        <div class="col-12 middle">
          <span class="image">
              <img class="col" src="/assets/images/logo.svg" alt="">
          </span>
        </div>
        <div class="col-12 margin-auto">
          <button [disabled]="isVP" (click)="loginSSO()" class="btn custom-btn" type="submit"><span *ngIf="inProgress" class="spinner-border sr-only"></span>&nbsp;Log in as Luxoft employee</button>
        </div>
      </div>
      <div class="col-md-1 col-sm-12 standard-size text-center"><span class="color-gray">or</span></div>
      <div class="col-md-4-5 col-sm-12 other-block">
        <div class="col-12 col-sm-12 upper margin-bottom-35">
          For other users / recruiting agencies
        </div>
        <form class="row form-content" [formGroup]="form" (ngSubmit)="login()">
          <div class="col-12 col-sm-12 email">
            <div class="form-group">
              <label for="email" class="control-label">Email</label>
              <input type="text" id="email" formControlName="email" class="form-control">
              <div *ngIf="form.get('email').invalid && form.get('email').touched" class="validation">
                <small *ngIf="form.get('email').errors['required']">Email cannot be empty</small>
                <small *ngIf="form.get('email').errors['email']">Write Correct Email</small>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-12 margin-bottom-25">
            <div class="form-group" style="position: relative">
              <div class="row">
                <div class="col-4 col-sm-4"><label for="password" class="control-label">Password</label></div>
                <div class="col-8 col-sm-8 password"> <a class="forgot-password" [routerLink]="['/password-recovery']">I forgot / don’t have password</a></div>
              </div>
              <input autocomplete="new-password" type="{{type}}" id="password" formControlName="password" class="form-control red-eye">
              <em><mat-icon class="icon" (click)="changeInputTypeP()">remove_red_eye</mat-icon></em>
              <div *ngIf="form.get('password').invalid && form.get('password').touched" class="validation">
                <small *ngIf="form.get('password').errors['required']">Password cant be empty</small>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-12 margin-bottom-5">
            <button [disabled]="inProgressOther" (click)="login()" class="btn custom-btn login-button" type="submit"><span *ngIf="inProgressOther" class="spinner-border sr-only"></span>&nbsp;Log in</button>
          </div>
        </form>
      </div>
    </div>
    <div class="row">
      <div class="col-md-5 col-sm-12">
      </div>
      <div class="col-md-4-5 col-sm-12">
        <div class="col-12 col-sm-12 text-center create-account">
          <p>Not in Referral Program yet? <a class="link" [routerLink]="['/external-users/register']">Create account</a></p>
        </div>
      </div>
    </div>
  </div>
</div>

