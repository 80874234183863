import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators} from "@angular/forms";
import {AuthService} from "../../service/auth.service";
import {RegisterRequest} from "../../dto/register-request";
import {SourceType} from "../../dto/source-type";
import {ToastrService} from "ngx-toastr";
import {TokenDTO} from "../../dto/token-dto";
import {ReplaySubject, takeUntil} from "rxjs";
import {ErrorDTO} from "../../dto/error-dto";
import {blankText, forbiddenStringsValidator} from "../referrer/send-recomendation/send-recommendation.component";

export const validPasswordPattern = '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!"#$%&\'()*+,-./:;<=>?@\x5B\x5C\x5D^_`{|}~]).{8,}$';

@Component({
  selector: 'app-external-user-register',
  templateUrl: './external-user-register.component.html',
  styleUrls: ['./external-user-register.component.css']
})
export class ExternalUserRegisterComponent implements OnInit, OnDestroy {

  email: string;
  form: FormGroup;
  countryList: string[] = ['Ukraine', 'Poland', 'Romania', 'Serbia', 'Mexico'];
  registerRequest: RegisterRequest;
  inProgress = false;
  token:string;
  inputP = 'password';
  inputCP = 'password';
  wrongEmail: boolean;
  destroy: ReplaySubject<void> = new ReplaySubject<void>(1);

  constructor(private router: Router,
              private authService: AuthService,
              private toastr: ToastrService,
              private activatedRoute: ActivatedRoute) {
    const navigation = this.router.getCurrentNavigation();
    if (navigation && navigation.extras) {
      const state = navigation.extras.state as { email: string };
      if (state)
        this.email = state.email;
    }

    this.form = new FormGroup({
      email: new FormControl(this.email, [Validators.email, Validators.required]),
      firstName: new FormControl('', [Validators.required, forbiddenStringsValidator(), Validators.pattern(blankText)]),
      lastName: new FormControl('', [Validators.required, forbiddenStringsValidator(), Validators.pattern(blankText)]),
      country: new FormControl(this.countryList[0], Validators.required),
      password: new FormControl('', [Validators.required, Validators.pattern(validPasswordPattern)]),
      confirmPassword: new FormControl('', Validators.required),
    }, { validators: this.checkPasswords });
  }


  ngOnInit(): void {
    this.activatedRoute.queryParams.pipe(takeUntil(this.destroy)).subscribe(params => this.token = params['token']);
    if (this.token) {
      this.authService.finishRegistration(new TokenDTO(this.token)).pipe(takeUntil(this.destroy)).subscribe( {
        next: (response) => {
          this.toastr.success(response.message, '');
          this.router.navigate(['/']);
        },
        error: (error) => {
          let errors = <ErrorDTO[]>error.error.errors;
          errors.forEach(e=> this.toastr.error(e.errorMessage, ''));
          this.router.navigate(['/']);
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  submit() {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      if (this.form.errors && this.form.errors['notSame']) {
        this.toastr.error('Password & Confirm Password must be the same', '');
      }
    } else {
      this.inProgress = true;
      this.registerRequest = {...this.registerRequest, ...this.form.value};
      this.registerRequest.platformType = SourceType.REF;
      this.registerRequest.password = btoa(this.registerRequest.password);
      this.registerRequest.confirmPassword = btoa(this.registerRequest.confirmPassword);
      this.authService.registrationExternalUser(this.registerRequest).pipe(takeUntil(this.destroy))
        .subscribe({
          next: (response) => {
            this.toastr.success(response.message, '');
            this.router.navigate(['/']);
            this.inProgress = false;
          },
          error: (error) => {
            let errors = <ErrorDTO[]>error.error.errors;
            errors.forEach(e=> this.toastr.error(e.errorMessage, ''));
            this.inProgress = false;
          }
        });
    }
  }

  checkPasswords: ValidatorFn = (group: AbstractControl):  ValidationErrors | null => {
    const pass = group.get('password').value;
    const confirmPass = group.get('confirmPassword').value;
    return pass === confirmPass ? null : { notSame: true };
  };

  changeInputTypeCP() {
    this.inputCP = this.inputCP === 'password' ? 'text' : 'password';
  }

  changeInputTypeP() {
    this.inputP = this.inputP === 'password' ? 'text' : 'password';
  }

  checkWrongAddress() {
    this.wrongEmail = this.form.get('email').value.endsWith('.pl');
  }
}
